/* Style for the top-rank item (matches selected button state) */
.top-rank {
  position: absolute;
  top: 0px;
  right: 0px;
  align-items: center;
  justify-content: center;
  font-size: 60px;
  color: white;
  background: rgba(0, 0, 0, 1); /* Dark background */
  border-radius: 0px;
  padding: 0px 20px 0px 2200px;
  z-index: 1;
  opacity: .8;
  display: block; /* Visible by default */
  transition: opacity 0.3s ease;
}

/* Hide top-rank on hover */
.grid-item:hover .top-rank {
  display: none; /* Completely hide the rank on hover */
}

/* Overlay Buttons: Initially Hidden */
.overlay-buttons {
  display: none;
  position: absolute;
  top: 10px;
  opacity: 0;
  left: 10px;
  z-index: 2;
}

/* Show buttons only on hover */
.grid-item:hover .overlay-buttons {
  display: flex; /* Show on hover */
  justify-content: space-around;
  gap: 5px;
}

/* Button styling for overlay */
.btn-overlay {
  background: rgba(0, 0, 0, 0.2); /* Default background */
  color: transparent; /* Hidden text */
  border: 1px solid white; /* Border for visibility */
  border-radius: 6px;
  padding: 15px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease, background 0.3s ease, transform 0.3s ease;
  opacity: 0.7; /* Adjust visibility of the button itself */
}

/* Selected Button: Always Visible and Highlighted */
.btn-overlay.btn-selected {
  color: white; /* Always visible text for selected button */  
  font-weight: bold;
  transform: scale(1.2); /* Slightly larger for emphasis */
  opacity: 1; /* Fully visible */
}

 
 

/* Overlay Container */
.overlay-buttons {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px); /* Center-align buttons */
  display: flex;
  justify-content: space-around; /* Space out buttons evenly */
  opacity: 0; /* Fully visible buttons */
  z-index: 2;
}

/* On Hover */
.grid-item:hover .btn-overlay {
  background: blue; /* Darker background on hover */
  color: white; /* Show text on hover */
}


/* Disabled Button */
.btn-overlay:disabled {
  cursor: not-allowed;
}

/* Highlight Current State */
.btn-overlay.btn-selected {
  color: white; /* Show text for selected button */
  background: rgba(0, 128, 255, 1); /* Highlight current position */
  font-weight: bold;
  transform: scale(1.2); /* Slightly larger for emphasis */
  opacity: 1; /* Fully visible */
}

/* On Hover */
.grid-item:hover .overlay-buttons {
  opacity: 1; /* Fully visible */
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.grid-item {
  position: relative;
  flex-direction: column; /* Ensures buttons appear on top */
  align-items: center;
}

.hover-controls {
  display: none;
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  justify-content: space-between;
}

.grid-item:hover .hover-controls {
  display: flex;
}

.hover-controls button {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.btn {
  padding: 5px 10px;
  font-size: 12px;
}

.num-btn {
  padding: 5px 10px;
  font-size: 32px;
}

.quick-pick-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #181a1f;
  color: white;
  border-radius: 10px;
  text-align: center;  
  margin: 0 auto;
  position: relative; /* Added to contain the pseudo-element */
  overflow: hidden; /* Ensure content is contained */
}

.watchlist-game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #181a1f;
  color: white;
  border-radius: 10px;
  text-align: center;  
  margin: 0 auto;
  position: relative; /* Added to contain the pseudo-element */
  overflow: hidden; /* Ensure content is contained */
}

.quick-pick-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.quick-pick-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/static/images/quick_picks_1.png");
  background-size: inherit;
  background-position: inherit;
  background-repeat: inherit;
  opacity: 0.2;
  z-index: 0;
}

.quick-pick-content > * {
  z-index: 2;
}

.quick-pick-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.quick-pick-container h2 {
  text-align: left;
  margin: 0;
}

.quick-pick-container p {
  text-align: center;
}

.quick-pick-container img {
  width: 150px;
  border-radius: 10px;
  margin: 10px 0;
}

.quick-pick-container button {
  border: none;
  cursor: pointer;
}

.grid-container {
  flex-direction: column;
  padding: 20px;
}

.grid-item {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.2s;
}

.grid-item img {
  height: auto;
  border-radius: 10px;
}

.grid-item p {
  margin-top: 10px;
  font-size: 14px;
}

.grid-item:hover {
  transform: scale(1.05);
}

.timer {
  height: 5px;
  background-color: darkgray;
}

.grid-container-all {
  display:flex;
  overflow-x: scroll;
  gap: 10px;
  padding: 10px;
}

.grid-container-later {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.personality-groups {
  padding: 20px;
}

.personality-group {
  margin-bottom: 20px;
}

.personality-types-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.personality-type-card {
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(25% - 20px);
  border: 0px solid darkgray;
  padding: 10px;
  border-radius: 10px;
  background-color: #1f2028;
}

.personality-type-card i {
  margin-bottom: 10px;
}

.remove-btn {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: none;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 36px;
  opacity: .65;
}

.hide {
  display: none;
}

.hover-overlay {
  position: absolute;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  display: flex;
  opacity: 0;
  transition: opacity .75s;
}

.grid-item:hover .hover-overlay {
  opacity: 1;
}

.watch-soon-overlay {
  bottom: 0px;
  left: 0px;
  width: 260px;
}

.watch-later-overlay {
  bottom: 10px;
  left: 0px;
  width: 260px;
}

.watch-soon-overlay i,
.watch-later-overlay i {
  margin-right: 5px;
}

.remove-overlay i {
  font-size: 24px;
  color: lightgray;
}

.remove-overlay span {
  font-size: 14px;
  margin-top: 5px;
  color: lightgray;
}

.add-overlay i {
  font-size: 24px;
  color: white;
}

.add-overlay span {
  font-size: 14px;
  margin-top: 5px;
  color: white;
}

.delete-btn {
  position: absolute;
  top: 25px;
  left: 5px;
  color: red;
  border: none;
  cursor: pointer;
  display: none;
}

.delete-later-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  color: red;
  border: none;
  cursor: pointer;
  display: none;
}

.grid-item:hover .delete-btn {
  display: block;
}

.grid-item:hover .delete-later-btn {
  display: block;
}
